<template>
  <div class="card border">
    <div class="card-header d-flex justify-content-between" style="height: 56px">
      <div style="font-size: 14px">{{ $t('bookkeeping.bookkeepingManagement.bookkeepingDetails.header') }}</div>
      <el-dropdown trigger="click" @command="handleCommand">
        <Button type="text" class="p-1 text-typography-primary secondary-hover-btn button">
          <KebabIcon />
        </Button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="COMMAND_TYPES.EDIT_CONFIG">
            <p class="text-typography-primary">
              <EditIcon />
              {{ $t('bookkeeping.bookkeepingManagement.bookkeepingDetails.actions.editBookkeeper') }}
            </p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-loading="loading" class="card-body row g-0 py-4 px-5 lh-1">
      <div class="mb-4">
        <h2 v-if="companyName" class="fw-bold">
          {{ companyName }}
        </h2>
        <h2 v-else>-</h2>
        <div class="d-flex gap-2">
          <p class="fw-bold mb-1">
            {{ translate('createForm.field.companyCrn') }}
          </p>
          <p v-if="bookkeeperCrn" class="fw-bold">{{ bookkeeperCrn }}</p>
          <p v-else>-</p>
        </div>
      </div>
      <div>
        <div class="d-flex gap-2 mt-2">
          <p class="fw-bold mb-1 field">
            {{ translate('createForm.field.phone') }}
          </p>
          <p v-if="bookkeeperPhone">{{ bookkeeperPhone }}</p>
          <p v-else>-</p>
        </div>
        <div class="d-flex gap-2 mt-2">
          <p class="fw-bold mb-1 field">
            {{ translate('createForm.field.email') }}
          </p>
          <p v-if="bookkeeperEmail">{{ bookkeeperEmail }}</p>
          <p v-else>-</p>
        </div>
        <div class="d-flex gap-2 mt-2">
          <p class="fw-bold mb-1 field">
            {{ translate('info.contact') }}
          </p>
          <p v-if="bookkeeperContact">{{ bookkeeperContact }}</p>
          <p v-else>-</p>
        </div>
      </div>
    </div>
    <BookkeeperForm v-if="showBookkeeperForm" :config="config" @close="showBookkeeperForm = false" />
  </div>
</template>

<script>
import { KebabIcon, EditIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

import BookkeeperForm from './BookkeeperForm';

const COMMAND_TYPES = {
  EDIT_CONFIG: 'editConfig',
};

export default {
  components: {
    KebabIcon,
    EditIcon,
    Button,
    BookkeeperForm,
  },
  props: {
    config: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },
  data() {
    return {
      COMMAND_TYPES,
      showBookkeeperForm: false,
    };
  },
  computed: {
    companyName() {
      return this.config.bookkeeper?.company.name;
    },
    bookkeeperContact() {
      return this.config.bookkeeper ? `${this.config.bookkeeper.firstName} ${this.config.bookkeeper.lastName}` : null;
    },
    bookkeeperEmail() {
      return this.config.bookkeeper?.email;
    },
    bookkeeperCrn() {
      return this.config.bookkeeper?.company.crn;
    },
    bookkeeperPhone() {
      return this.config.bookkeeper?.phone;
    },
    billingSending() {
      return this.config.billingSending;
    },
    paymentSending() {
      return this.config.paymentSending;
    },
    documentSending() {
      return this.config.documentSending;
    },
  },
  methods: {
    translate(key) {
      return this.$t(`bookkeeping.bookkeepingManagement.bookkeeperInfo.${key}`);
    },
    handleCommand(command) {
      switch (command) {
        case COMMAND_TYPES.EDIT_CONFIG:
          this.showBookkeeperForm = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.margin-top-fix {
  margin-top: 0.0625rem;
}

.secondary-hover-btn {
  &:hover {
    background: $secondary;
  }
}

.button {
  height: 2rem;
  width: 2rem;
}

.field {
  width: 83px;
}
</style>
