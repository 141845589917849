<template>
  <el-dialog visible :show-close="false" custom-class="rounded bookkeeper-form" width="400px" @close="$emit('close')">
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2 class="fw-bold">{{ translate('title') }}</h2>
        <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="p-4 details border-bottom">
      <el-form
        ref="form"
        :model="formBookkeeperModel"
        label-position="top"
        :show-message="false"
        @submit.native.prevent="onSubmit"
      >
        <div class="mb-2">
          <div class="fw-bold">{{ translate('header.business') }}</div>
          <el-form-item required :label="translate('field.companyName')" prop="companyName" class="mb-0">
            <el-input v-model="formBookkeeperModel.companyName" />
          </el-form-item>
          <el-form-item required :label="translate('field.companyCrn')" prop="companyCrn" class="mb-0">
            <el-input v-model="formBookkeeperModel.companyCrn" />
          </el-form-item>
        </div>
        <div class="d-flex flex-column gap-0">
          <div class="fw-bold">{{ translate('header.contact') }}</div>
          <div>
            <div class="d-flex justify-content-between gap-4">
              <el-form-item required :label="translate('field.firstName')" prop="firstName" class="mb-0">
                <el-input v-model="formBookkeeperModel.firstName" />
              </el-form-item>
              <el-form-item required :label="translate('field.lastName')" prop="lastName" class="mb-0">
                <el-input v-model="formBookkeeperModel.lastName" />
              </el-form-item>
            </div>
            <el-form-item required :label="translate('field.phone')" prop="phone" class="mb-0">
              <el-input v-model="formBookkeeperModel.phone" dir="ltr" />
            </el-form-item>
            <el-form-item :error="error" :label="translate('field.email')" prop="email" class="mb-0">
              <el-input v-model="formBookkeeperModel.email" dir="ltr" />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="pb-4 px-4 mx-0 my-4">
        <Button type="secondary" @click="$emit('close')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="onSubmit">
          {{ $t('commons.save') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { isNil, omit, defaultTo, reject } from 'ramda';
import { computed } from 'vue';

import { CloseIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import { useConfigCreate, useConfigUpdate } from '../composition/config';

export default {
  components: { CloseIcon, Button },
  props: {
    config: { type: Object, required: true },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const { createConfig: createBookkeeper } = useConfigCreate(computed(() => currentTenant.value.id));
    const { updateConfig: updateBookkeeper } = useConfigUpdate();

    return {
      createBookkeeper,
      updateBookkeeper,
      currentTenant,
    };
  },
  data() {
    const bk = defaultTo({ company: { name: null, crn: null } }, this.config.bookkeeper);
    return {
      loading: false,
      error: null,
      formBookkeeperModel: {
        companyName: bk.company.name,
        companyCrn: bk.company.crn,
        firstName: bk.firstName,
        lastName: bk.lastName,
        phone: bk.phone,
        email: bk.email,
      },
    };
  },
  methods: {
    translate(key) {
      return this.$t(`bookkeeping.bookkeepingManagement.bookkeeperInfo.createForm.${key}`);
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.error = null;

          const data = reject(isNil, {
            bookkeeper: {
              ...omit(['companyName', 'companyCrn'], this.formBookkeeperModel),
              company: {
                crn: this.formBookkeeperModel.companyCrn,
                name: this.formBookkeeperModel.companyName,
              },
            },
          });

          try {
            if (!this.config.id) await this.createBookkeeper({ data: { ...data, businessId: this.currentTenant.id } });
            else await this.updateBookkeeper({ id: this.config.id, data });
            this.$emit('close');
          } catch (error) {
            this.loading = false;
            this.error = 'bad email syntax';
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

:deep(.el-input__inner) {
  height: 2rem;
  border-color: $gray;
}

:deep(.bookkeeper-form) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}
.details {
  font-size: 0.875rem;
}

:deep(.el-form-item__content) {
  line-height: 0.5rem;
}
</style>
